import React, {createContext, useReducer} from "react";
import combineReducers from 'react-combine-reducers';
import {trackingContext} from "../reducers";

const [appReducer, initialStates] = combineReducers({
  tracking: [trackingContext.reducer, trackingContext.initialState]
});
export const AppContext = createContext();

export const AppContextProvider = (props, {value}) => {
  const [state, dispatch] = useReducer(appReducer, initialStates);
  return (
    <AppContext.Provider value={value ? [...value] : [state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  )
}
