// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-activate-js": () => import("./../../../src/pages/account/activate.js" /* webpackChunkName: "component---src-pages-account-activate-js" */),
  "component---src-pages-account-remove-checkout-data-js": () => import("./../../../src/pages/account/removeCheckoutData.js" /* webpackChunkName: "component---src-pages-account-remove-checkout-data-js" */),
  "component---src-pages-account-reset-js": () => import("./../../../src/pages/account/reset.js" /* webpackChunkName: "component---src-pages-account-reset-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-refer-index-js": () => import("./../../../src/pages/refer/index.js" /* webpackChunkName: "component---src-pages-refer-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-templates-dealer-js": () => import("./../../../src/templates/Dealer.js" /* webpackChunkName: "component---src-templates-dealer-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

