import fetch from "cross-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client"

import resolvers from "../graphql/resolvers"

export const client = new ApolloClient({
  ssrMode: false,
  link: new HttpLink({
    uri: "https://not-pot.myshopify.com/api/2020-07/graphql.json",
    headers: {
      "X-Shopify-Storefront-Access-Token": "04d30f243880b321f16da49137cc41fc",
    },
    fetch,
  }),
  cache: new InMemoryCache().restore({}),
  resolvers,
})
